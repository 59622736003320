import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {Router} from '@angular/router';
import {Injectable} from "@angular/core";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private router: Router) {
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const tokenName = "token";
        const tokenValue = localStorage.getItem(tokenName);
        const agenceName = "agence";
        let agenceValue = localStorage.getItem(agenceName);
        let protocol = "https://";
        if (agenceValue === null || tokenValue === null) {
            if (/devsequentiel/.test(window.origin)) {
                window.location.replace("https://gestion.homepart.devsequentiel.net");
            }
            // dev
            else if (/localhost/.test(window.origin)) {
                window.location.replace("http://gestion.homepart.localhost");
            } else {
                window.location.replace("https://gestion.homepart.fr");
            }
        }
        if (/localhost/.test(agenceValue)) {
            protocol = "http://";
        }
        let url = `${protocol}${agenceValue}`;
        url += req.url;
        const cloneReq = req.clone({
            url: url,
            params: req.params.set(tokenName, tokenValue),
        });
        return next.handle(cloneReq).pipe(
            catchError((error) => {
                console.log('Erreur');
                const tokenName = "token";
                const tokenValue = localStorage.getItem(tokenName);
                if (error.status === 403 || tokenValue === null) {
                    if (/devsequentiel/.test(window.origin)) {
                        window.location.replace("https://gestion.homepart.devsequentiel.net");
                    }
                    // dev
                    else if (/localhost/.test(window.origin)) {
                        window.location.replace("http://gestion.homepart.localhost");
                    } else {
                        window.location.replace("https://gestion.homepart.fr");
                    }
                }
                return throwError(error);
            })
        );
    }
}
