import { Component } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { catchError } from "rxjs/operators";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private httpClient: HttpClient
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {
    this.storeToken();
    this.storeAgence();
  }
  storeToken(tokenName = "token"): void {
    // const tokenName = "token";
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const tokenValue = urlParams.get(tokenName);
    // stockage du token
    if (tokenValue !== null) {
      localStorage.setItem(tokenName, tokenValue);
    }
  }

  storeAgence(agenceName = "agence"):void {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const agenceValue = urlParams.get(agenceName);
    // stockage de l'agence
    if (agenceValue !== null) {
      localStorage.setItem(agenceName, agenceValue);
    }
  }
}
